<template>
    <div class="main box">
        <div class="banner">
            <div class="filter-container">
                <select class="btn-websites" v-model="websites" @change="filterWebsites">
                    <option value="0">All websites</option>
                    <option value="1">Safe Group</option>
                    <option value="2">Safe Orthopaedics</option>
                    <option value="3">Safe Medical</option>
                </select>
            </div>
            <router-link class="btn" :to="{ name : 'AddCalendar'}">Add</router-link>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Days</th>
                        <th>Event</th>
                        <th>Sites</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in listFilter" :key="idx"> 
                        <td>{{ monthNames[item.month - 1] }}</td>
                        <td>{{ item.start }} <template v-if="item.end"> - {{ item.end }}</template></td>
                        <td class="event-text">
                            <template v-if="item.en">[EN] {{ item.en }}<br /></template>
                            <template v-if="item.fr">[FR] {{ item.fr }}<br /></template>
                            <template v-if="item.de">[DE] {{ item.de }}</template>
                        </td>
                        <td v-html="getWebsites(item.websites)"></td>
                        <td>
                            <div class="actions-container">
                                <router-link :to="{ name : 'UpdateCalendar', params : { event : item._id}}" class="post-btn icon-otw_icon_edit"></router-link>
                                <div class="post-btn icon-otw_icon_trash" @click="confirmId = item._id"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <confirm-delete 
        v-if="confirmId"
        :id="confirmId"
        v-on:close="confirmId = null"  
        v-on:confirmDelete="confirmDelete"
        type="event"  
    ></confirm-delete>
</template>

<script>
import axios from "axios";
import confirmDelete from "./../components/ConfirmDelete.vue";

export default {
    name : "Calendar",
    components : {
        confirmDelete
    },
    data() {
        return {
            websites : 0,
            list : [],
            monthNames : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            confirmId : null
        }
    },
    methods : {
        getData() {
            let config = {
                validateStatus: () => true,  
            };
            config.headers = {  'x-access-token' : localStorage._safeToken }
            let vm = this;
                axios
                    .get(`${this.$store.state.apiUrl}/events`,  config)
                    .then(function (res) {                
                        if(res.status != 200) {
                            const notice = {
                                msg : res.data.errors,
                                error : true
                            }
                            vm.$store.commit('setNotice', notice);                   
                        }else{
                            vm.list = res.data.message; 
                       
                        }
                    });
        },
        getWebsites(sites) {
            let res = "";
            if(sites.includes(1)) {
                res += "Safe Group<br />";
            }
            if(sites.includes(2)) {
                res += "Safe Orthopaedics<br />";
            }
            if(sites.includes(3)) {
                res += "Safe Medical<br />";
            }
            if(sites.includes(4)) {
                res += "LinkedIn<br />";
            }
            return res;            
        },
        confirmDelete(_id) {
            this.confirmId = null;
            this.list = this.list.filter(e => e._id != _id);
        }
    },
    mounted() {
        this.getData();
    },
    computed : {
        listFilter() {
            if(this.websites && this.websites != 0) {
                return this.list.filter(e => e.websites.includes(parseInt(this.websites)) );
            }else {
                return this.list;
            }
             
        }
    },

}
</script>

<style lang="scss" scoped>
    .banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn {
        width:150px;
        text-transform: uppercase;
        text-align: center;
        padding:11px 25px;
    }

    .btn-websites {
        min-width: 250px;
    }

    .event-text {
        text-align: left;
    }
</style>